import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { FormProvider, useForm } from "react-hook-form";
import { isFuture, isValid } from "date-fns";
import {
  AmountOfMoney,
  Items,
  Method,
  PageResponse,
  ProvideDetailsRequest,
} from "../../api/client";
import usePageResponse from "../../hooks/use-page-response";
import ErrorPage from "../ErrorPage";
import LanguageSwitch from "../../components/LanguageSwitch";
import { useTranslation } from "react-i18next";
import { fieldNames, formFieldErrorMap } from "../../types/formField";
import { CardDetails } from "../../types/CardDetails";
import { FormDetails } from "../../types/FormDetails";
import { CreditCard } from "./PaymentMethods/CreditCard";
import CurrencyConfig from "../../resources/currency-config.json";
// import GooglePayButton from "./PaymentMethods/GooglePay/GooglePayButton";
import ApplePayButton from "./PaymentMethods/ApplePay/ApplePayButton";
import PaypalButton from "./PaymentMethods/Paypal/PaypalButton";

type PropsType = {
  hostedPagesId: string;
  pageResponse: PageResponse | undefined;
};

function MethodDetailsPage({ hostedPagesId, pageResponse }: PropsType) {
  console.log("page response: ", pageResponse);
  const { t, ready: areTranslationsAvailable } = useTranslation("translation", {
    useSuspense: false,
  });
  const pageResponseMethods = usePageResponse(hostedPagesId);
  const {
    provideDetails: [mutate, { isError, reset }],
  } = pageResponseMethods;
  const formMethods = useForm<FormDetails>();

  const {
    handleSubmit,
    setError,
    errors: formErrors,
    control,
    setValue,
    trigger,
  } = formMethods;

  const [methods] = useState<Method[]>([]);

  useEffect(() => {
    if (methods.length > 0) {
      setValue("brand", methods[0].name);
    }
  }, [methods, setValue]);

  /**
   * Add an error to the errors displayed by the form
   */
  const showError = pageResponse?.pageDetails?.showError;
  useEffect(() => {
    if (showError && formFieldErrorMap.has(showError)) {
      const errorField = formFieldErrorMap.get(showError);
      const field = fieldNames.find((field) => field === errorField);
      if (field && field !== "encryptedData") {
        setError(field, {
          type: `${showError}`,
          message: t(showError),
        });
      }
    }
  }, [showError, setError, t]);

  function isCreditCard(method?: Method) {
    if (!method) return false;
    return [
      Items.Visa,
      Items.Mastercard,
      Items.CarteBancaire,
      Items.Card,
    ].includes(method.name);
  }

  function validateCreditCardValues(month: string, year: string) {
    if (Number(month) > 11) {
      setError("expiryDate", {
        type: "manual_expiry_date_month_or_year_undefined",
        message: t("The expiry date is incorrect"),
      });
      return;
    }

    const date = new Date(Number(year), Number(month));
    if (!isValid(date)) {
      setError("expiryDate", {
        type: "manual_expiry_date_invalid",
        message: t("The expiry date is incorrect"),
      });
      return;
    }
    if (!isFuture(date)) {
      setError("expiryDate", {
        type: "manual_expiry_date_not_in_future",
        message: t("The date should be in the future"),
      });
      return;
    }
  }

  function convertAmountOfMoney(amountOfMoney: AmountOfMoney) {
    if (
      amountOfMoney.currencyCode != null &&
      CurrencyConfig.currency.codeWithoutCents.includes(
        amountOfMoney.currencyCode.toUpperCase()
      )
    ) {
      return Number(amountOfMoney.amount);
    } else {
      return Number(amountOfMoney.amount) / 100;
    }
  }

  const provideDetails = async (formDetails: FormDetails) => {
    // trigger form validation
    await trigger();

    if (!pageResponse?.pageDetails?.method) {
      throw Error(t("No method has been selected on the select method page"));
    }
    const { method } = pageResponse.pageDetails;

    const provideDetailsRequest: ProvideDetailsRequest = {
      method: String(method.name),
      threedsData: {
        colorDepth: window.screen.colorDepth,
        javaEnabled: navigator.javaEnabled(),
        screenHeight: String(window.screen.height),
        screenWidth: String(window.screen.width),
        timezoneOffset: String(new Date().getTimezoneOffset()),
      },
    };

    console.log(method.name);
    if (isCreditCard(method)) {
      const [m, y] = formDetails.expiryDate.split("/");
      const month = Number(m) - 1 + "";
      const year = `20${y}`;
      validateCreditCardValues(month, year);
      provideDetailsRequest.cardDetails = {
        cardHolderName: formDetails.cardHolderName,
        securityCode: formDetails.securityCode,
        cardNumber: formDetails.cardNumber.split(" ").join(""),
        expiryYear: y,
        expiryMonth: m,
        brand: pageResponse.pageDetails.iinLookupAvailable
          ? formDetails.brand
          : method.name,
      } as CardDetails;
    }
    // else if (method.name === Items.GooglePay) {
    //   provideDetailsRequest.mobilePayDetails = {
    //     encryptedData: formDetails.encryptedData,
    //   } as MobilePayDetails; }
    else if (method.name === Items.ApplePay) {
      // Apple Pay
    }

    console.log("final details", provideDetailsRequest);
    if (Object.keys(formErrors).length === 0) {
      return mutate({ hostedPagesId, provideDetailsRequest });
    }
  };

  if (isError) {
    return (
      <ErrorPage>
        <Typography component="h6" variant="h6">
          {t("You can retry by clicking below")}
        </Typography>
        <Button onClick={reset}>{t("Reset")}</Button>
      </ErrorPage>
    );
  }

  if (areTranslationsAvailable === false) {
    return <CircularProgress />;
  }

  const locale =
    pageResponse?.eagleStyle?.language ||
    pageResponse?.pageDetails?.language ||
    "en-US";

  if (!pageResponse?.pageDetails) {
    // TODO add a good error here
    // OR show a loader while the response is not available
    throw new Error();
  }
  const { method } = pageResponse.pageDetails;

  const demoApplePay = {
    countryCode: "US",
    currencyCode: "USD",
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: ["visa", "masterCard", "amex", "discover"],
    total: {
      label: "Demo (Card is not charged)",
      type: "final",
      amount: "1.99",
    },
  };
  const demoPayPal = {
    flow: "checkout",
    amount: "10.0",
    currency: "EUR",
    intent: "capture",
  } as const;
  const determinePaymentMethod = () => {
    switch (true) {
      case isCreditCard(method):
        return (
          <CreditCard
            pageResponse={pageResponse}
            pageResponseMethods={pageResponseMethods}
            hostedPagesId={hostedPagesId}
          />
        );
      case method?.name === Items.Paypal:
        return <PaypalButton payment={demoPayPal} />;
      // case method?.name === Items.GooglePay:
      //   return (
      //     <GooglePayButton
      //       amount="100.00"
      //       onSubmit={provideDetails}
      //       pageResponseMethods={pageResponseMethods}
      //       hostedPagesId={hostedPagesId}
      //     />
      //   );
      case method?.name === Items.ApplePay:
        return <ApplePayButton payment={demoApplePay}></ApplePayButton>;
      default:
        return (
          <CreditCard
            pageResponse={pageResponse}
            pageResponseMethods={pageResponseMethods}
            hostedPagesId={hostedPagesId}
          />
        );
    }
  };

  return (
    <Box m={2}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(provideDetails)}>
          <Grid item xs={12}>
            <Box display="none" justifyContent="flex-end" pb={2}>
              <LanguageSwitch
                control={control}
                initialLanguage={locale}
              ></LanguageSwitch>
            </Box>
          </Grid>
          <Grid container spacing={1}>
            {pageResponse?.pageDetails?.amountOfMoney && (
              <Grid item xs={6} sm={2}>
                <Typography variant="h6" component="h1" color="textSecondary">
                  {t("amount")}
                </Typography>
              </Grid>
            )}
            {pageResponse?.pageDetails?.amountOfMoney && (
              <Grid item xs={6} sm={6}>
                <Typography variant="h6" component="h1" color="textSecondary">
                  {Number(
                    convertAmountOfMoney(
                      pageResponse?.pageDetails?.amountOfMoney
                    )
                  ).toLocaleString(locale, {
                    style: "currency",
                    currency:
                      pageResponse?.pageDetails?.amountOfMoney?.currencyCode,
                  })}
                </Typography>
              </Grid>
            )}
            {determinePaymentMethod()}
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
}

export default MethodDetailsPage;
