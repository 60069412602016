import React, { ImgHTMLAttributes } from "react";
import { makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    width: "100%",
    height: "auto",
  },
});

function PaymentMethodLogo({ src, alt }: ImgHTMLAttributes<{}>) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img className={classes.img} src={src} alt={alt} />
    </Box>
  );
}

export default PaymentMethodLogo;
