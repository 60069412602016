import { useRef } from "react";
import defaultStyling from "../utils/defaultStyling";
import { Overrides } from "@material-ui/core/styles/overrides";
import { PageResponse } from "../api/client";

/**
 * Returns parsed style overrides for Material UI, or returns the default styles in case of an error
 */
export default function useExternalStyle(appState?: PageResponse) {
  const styleRef = useRef({});

  try {
    styleRef.current = JSON.parse(appState?.eagleStyle?.css as string);
  } catch (error) {
    styleRef.current = JSON.parse(defaultStyling());
  }
  return styleRef.current as Overrides;
}
