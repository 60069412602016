import api from "../api";
import { ClientSessionType } from "../api/client";

type MerchantValidationPayload = {
  hostedPagesId: string;
};

async function validateApplePayMerchant({
  hostedPagesId,
}: MerchantValidationPayload) {
  return api.createClientSession(hostedPagesId, {
    clientSessionType: ClientSessionType.APPLEPAY,
    sessionTypeSpecificInput: {
      applePay: {
        validationEndpoint:
          "https://apple-pay-gateway.apple.com/paymentservices/paymentSession",
        domainName: "flowbird.eu.ngrok.io",
      },
    },
  });
}

export default validateApplePayMerchant;
