import React, { useState } from "react";
import { useEffect } from "react";
import useHostedPagesId from "../../../../hooks/use-hosted-pages-id";
import { ClientSessionType } from "../../../../api/client";
import {
  PayPalScriptProvider,
  BraintreePayPalButtons,
  OnApproveBraintreeActions,
  CreateOrderBraintreeActions,
} from "@paypal/react-paypal-js";
import {
  BraintreePayPalCheckoutCreatePaymentOptions,
  BraintreePayPalCheckoutTokenizationOptions,
} from "@paypal/react-paypal-js/dist/types/types/braintree/paypalCheckout";
import usePageResponse from "../../../../hooks/use-page-response";
import { useCreateClientSession } from "../../../../hooks/useCreateClientSession";

type Props = {
  payment: BraintreePayPalCheckoutCreatePaymentOptions;
};
const PaypalButton = ({ payment }: Props) => {
  const hostedPagesId = useHostedPagesId();
  const {
    provideDetails: [provideDetails],
  } = usePageResponse(hostedPagesId);
  const [clientToken, setClientToken] = useState<string | undefined>(undefined);
  const paymentRequest = {
    clientSessionType: ClientSessionType.PAYPAL,
  };
  const { error, data: sessionData } = useCreateClientSession(
    hostedPagesId,
    paymentRequest
  );
  useEffect(() => {
    if (sessionData && !error) {
      const { data } = sessionData;
      setClientToken(
        data?.sessionTypeSpecificOutput?.paypal?.paypalClientToken
      );
    }
  }, [sessionData, error]);

  const onApprove = async (
    data: BraintreePayPalCheckoutTokenizationOptions,
    actions: OnApproveBraintreeActions
  ) => {
    const payload = await actions.braintree.tokenizePayment(data);
    await provideDetails({
      hostedPagesId: hostedPagesId,
      provideDetailsRequest: {
        method: ClientSessionType.PAYPAL,
        mobilePayDetails: {
          paymentData: payload.nonce,
        },
      },
    });
  };

  const createOrder = (data: any, actions: CreateOrderBraintreeActions) => {
    return actions.braintree.createPayment(payment);
  };

  if (!clientToken) return null;

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AUVs8xiPAHwakAH84bNP2jWthCDw9GiwMkuv3kvsNvp-6h4E4lfKFJmW0Mq99NPUUgsKM_HEJxlBRiap",
        "data-client-token": clientToken,
        currency: payment.currency,
      }}
    >
      <BraintreePayPalButtons
        fundingSource="paypal"
        createOrder={createOrder}
        onApprove={onApprove}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalButton;
