import React, { useEffect, useState, useCallback } from "react";
import { Button, CircularProgress, Typography, Box } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";
import { Page } from "../api/client";
import MethodSelectionPage from "../Pages/MethodSelectionPage";
import MethodDetailsPage from "../Pages/MethodDetailPage/MethodDetailsPage";
import usePageResponse from "../hooks/use-page-response";
import useHostedPagesId from "../hooks/use-hosted-pages-id";
import ErrorPage from "../Pages/ErrorPage";
import RedirectToThirdPartyPage from "../Pages/RedirectToThirdPartyPage";
import Pages from "./Pages";
import { AxiosError } from "axios";
import handleError from "../utils/handleError";
import useExternalStyle from "../hooks/use-external-style";
import { useTranslation } from "react-i18next";

declare global {
  interface Window {
    ApplePaySession: any;
  }
}

function toAxiosError(error: unknown): AxiosError | undefined {
  const possibleAxiosError = error as AxiosError;
  if (possibleAxiosError.isAxiosError) {
    return possibleAxiosError;
  }
  return undefined;
}

const theme = (overrides: Overrides) => {
  return createMuiTheme({
    overrides: {
      ...overrides,
    },
  });
};

function Layout() {
  const hostedPagesId = useHostedPagesId();
  const { t, ready } = useTranslation("translation", { useSuspense: false });
  const {
    pageResponse: { data: state, isError, isLoading, error },
    restart: [restart],
  } = usePageResponse(hostedPagesId);
  const style = useExternalStyle(state);
  const isApplePayInMethods = state?.pageDetails?.methods?.find(
    (method) => method.name === "apple_pay"
  );

  const [filteredState, SetFilteredState] = useState(
    state?.pageDetails?.methods
  );
  const [isFiltering, setFiltering] = useState(true);

  const ResetButton = () => (
    <Button onClick={() => restart({ hostedPagesId })}>{t("Reset")}</Button>
  );

  const CheckIfApplePayIsAvailable = useCallback(async () => {
    if (isApplePayInMethods && window.ApplePaySession) {
      const merchantIdentifier = "merchant.io.ngrok.eu.flowbird";
      console.log(
        await window.ApplePaySession.canMakePaymentsWithActiveCard(
          merchantIdentifier
        )
      );

      window.ApplePaySession.canMakePaymentsWithActiveCard(
        merchantIdentifier
      ).then(function (canMakePayments: Boolean) {
        if (canMakePayments) {
          console.log("Apple Pay available");
          SetFilteredState(state?.pageDetails?.methods);
          setFiltering(false);
        } else {
          console.log("Apple Pay not available");
          SetFilteredState(
            state?.pageDetails?.methods?.filter(
              (method) => method.name !== "apple_pay"
            )
          );
          setFiltering(false);
        }
      });
    } else {
      SetFilteredState(
        state?.pageDetails?.methods?.filter(
          (method) => method.name !== "apple_pay"
        )
      );
      setFiltering(false);
    }
  }, [state, isApplePayInMethods]);

  useEffect(() => {
    CheckIfApplePayIsAvailable();
  }, [CheckIfApplePayIsAvailable]);

  if (isError) {
    const axiosError = toAxiosError(error);
    if (axiosError?.isAxiosError) {
      handleError(axiosError);
    }
  }

  if (isLoading || !ready || isFiltering) {
    return <CircularProgress />;
  }

  return (
    <MuiThemeProvider theme={() => theme(style)}>
      <Pages activePage={state?.page}>
        <Box key={Page.SessionExpired}>
          <Typography component="h6" variant="h6">
            {t("Session expired")}
          </Typography>
        </Box>
        <Box key={Page.MerchantFailure}>
          <Typography component="h6" variant="h6">
            {t("Merchant failed to process the payment request")}
          </Typography>
        </Box>
        <RedirectToThirdPartyPage
          key={Page.MerchantSuccess}
          redirectUrl={state?.pageDetails?.action?.actionDetails?.url || ""}
        />
        <RedirectToThirdPartyPage
          key={Page.RedirectToThirdParty}
          redirectUrl={state?.pageDetails?.action?.actionDetails?.url || ""}
        />
        <Box key={Page.FlowbirdFailure}>
          <ErrorPage>
            <Typography component="h6" variant="h6">
              {t("Merchant failed to process the payment request")}
            </Typography>
            <ResetButton />
          </ErrorPage>
        </Box>
        <MethodSelectionPage
          key={Page.MethodSelection}
          hostedPagesId={hostedPagesId}
          pageResponse={state}
          filteredMethods={filteredState}
        />
        <MethodDetailsPage
          key={Page.MethodDetails}
          hostedPagesId={hostedPagesId}
          pageResponse={state}
        />
      </Pages>
    </MuiThemeProvider>
  );
}

export default Layout;
