import React, { useMemo } from "react";
import { InputAdornment } from "@material-ui/core";
import PaymentMethodLogo from "../../components/PaymentMethodLogo";
import { Items, Method } from "../../api/client";
import { useFormContext } from "react-hook-form";

interface Props {
  methodName?: string;
  logoUrl?: string;
  displayName?: string;
  paymentMethods: Array<Method>;
}

export const PaymentInputEndAdornment: React.FC<Props> = ({
  logoUrl,
  methodName,
  displayName,
  paymentMethods,
}) => {
  const methods = useFormContext();
  const { watch } = methods;

  const [logo, logoAltText] = useMemo(() => {
    if (methodName !== Items.Card && logoUrl && displayName) {
      return [logoUrl, displayName];
    }
    const paymentMethod = paymentMethods.find(
      (method) => method.displayName === watch("brand")
    );
    if (paymentMethod) {
      return [paymentMethod.logoUrl, paymentMethod.displayName];
    }
    return [undefined, ""];
  }, [displayName, logoUrl, methodName, paymentMethods, watch]);

  return (
    <InputAdornment position="end">
      <PaymentMethodLogo src={logo} alt={logoAltText} />
    </InputAdornment>
  );
};
