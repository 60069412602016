import { useQuery } from "react-query";
import api from "../api";
import { CreateClientSessionRequest } from "../api/client";

export const useCreateClientSession = (
  hostedPagesId: string,
  createClientSessionRequest: CreateClientSessionRequest
) =>
  useQuery({
    queryKey: ["paypal-session"],
    queryFn: async () =>
      await api.createClientSession(hostedPagesId, createClientSessionRequest),
  });
