import { ValidationRule } from "react-hook-form/dist/types/validator";
import { ErrorTypes } from "../api/client";

export const fieldNames = [
  "cardHolderName",
  "brand",
  "cardNumber",
  "securityCode",
  "expiryDate",
  "encryptedData",
] as const;

export type FormFields = (typeof fieldNames)[number];

export type FormFieldType = {
  label: string;
  name: FormFields;
  type?: string;
  autocomplete?: string;
  placeholder?: string;
  rules?: {
    [key: string]: ValidationRule;
  };
};

export const formFieldErrorMap = new Map<ErrorTypes, FormFields>([
  [ErrorTypes.CardBrand, "brand"],
  [ErrorTypes.CardNumber, "cardNumber"],
  [ErrorTypes.Cvv, "securityCode"],
  [ErrorTypes.ExpiryDate, "expiryDate"],
  [ErrorTypes.CardHolderName, "cardHolderName"],
]);
