import React from "react";

type PageProps = {};
type PagesProps = {
  activePage: string | undefined;
  children: React.ReactElement<PageProps>[];
};

/**
 * Renders a component based on its key prop if it matches activePage
 */
const Pages = ({ activePage, children }: PagesProps) => {
  if (!activePage) {
    throw new Error("No active page specified");
  }

  return <>{children.find((child) => child.key === activePage)}</>;
};

export default Pages;
