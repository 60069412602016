import {
  Configuration,
  StaticAssetsBINLookupApiFactory,
  StaticAssetsGetStateApiFactory,
  StaticAssetsUpdateStateApiFactory,
  StaticAssetsClientSessionsApiFactory,
} from "./client";
import { Overrides } from "@material-ui/core/styles/overrides";

const getStyles = async (): Promise<Overrides> => {
  try {
    const res = await fetch("http://localhost:9090/styles");
    return await res.json();
  } catch (e) {
    throw new Error(e);
  }
};

function configureClient(configuration?: Configuration) {
  return {
    ...StaticAssetsGetStateApiFactory(
      configuration,
      process.env.REACT_APP_API_URL
    ),
    ...StaticAssetsUpdateStateApiFactory(
      configuration,
      process.env.REACT_APP_API_URL
    ),
    ...StaticAssetsBINLookupApiFactory(
      configuration,
      process.env.REACT_APP_API_URL
    ),
    ...StaticAssetsClientSessionsApiFactory(
      configuration,
      process.env.REACT_APP_API_URL
    ),
    getStyles,
  };
}

export default configureClient();
