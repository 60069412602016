import React, { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Controller, useFormContext } from "react-hook-form";
import promiseDebounce from "awesome-debounce-promise";
import { Items, Method, PageResponse } from "../../../api/client";
import usePageResponse from "../../../hooks/use-page-response";
import FbInput from "../../../components/FbInput";
import FbFormField from "../../../components/FbFormField";
import ErrorText from "../../../components/ErrorText";
import { useTranslation } from "react-i18next";
import { FormFieldType } from "../../../types/formField";
import FbRadioGroup from "../../../components/FbRadioGroup";
import { CardDetails } from "../../../types/CardDetails";
import { makeStyles } from "@material-ui/core";
import MaskedExpiryInput from "../../../components/MaskedExpiryInput";
import { PaymentInputEndAdornment } from "../PaymentInputEndAdornment";
import CreditCardLogo from "../../../components/CreditCardLogo";

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
  },
});

interface Props {
  hostedPagesId: string;
  pageResponse?: PageResponse;
  pageResponseMethods: ReturnType<typeof usePageResponse>;
}

export const CreditCard: React.FC<Props> = ({
  hostedPagesId,
  pageResponse,
  pageResponseMethods,
}) => {
  const { t } = useTranslation("translation", {
    useSuspense: false,
  });
  const {
    provideDetails: [, { isLoading }],
    bINLookup: [bINLookup],
    restart: [restart],
  } = pageResponseMethods;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const formMethods = useFormContext<CardDetails>();
  const { register, errors, control, reset } = formMethods;
  const [methods, setMethods] = useState<Method[]>([]);
  const classes = useStyles();

  console.log(errors);

  useEffect(() => {
    if (pageResponse?.pageDetails?.cardDetails) {
      reset({
        cardNumber: formatCardNumber(
          pageResponse.pageDetails.cardDetails.cardNumber
        ),
      });
    }
  }, [reset, pageResponse?.pageDetails?.cardDetails]);

  const expiryFormField: FormFieldType = {
    label: t("expiryDate"),
    name: "expiryDate",
    autocomplete: "cc-exp",
    placeholder: t("MM/YY"),
    rules: {
      required: { value: true, message: t("Required field") },
      minLength: { value: 4, message: t("Input is too short") },
      maxLength: { value: 4, message: t("Input is too long") },
    },
  };

  const debounceBINLookup = promiseDebounce(bINLookup, 500);

  const cardNumberField: FormFieldType = {
    label: t("cardNumber"),
    name: "cardNumber",
    autocomplete: "cc-number",
    rules: {
      required: { value: true, message: t("Required field") },
      minLength: { value: 13, message: t("Input is too short") },
    },
  };
  const cardBrandField: FormFieldType = {
    label: t("brand"),
    name: "brand",
    rules: {
      required: { value: true, message: t("Required field") },
    },
  };

  const formFields: Array<FormFieldType> = [
    {
      label: t("cardHolderName"),
      name: "cardHolderName",
      autocomplete: "cc-name",
      rules: { required: { value: true, message: t("Required field") } },
    },
  ];
  const securityCodeField: FormFieldType = {
    label: t("securityCode"),
    name: "securityCode",
    type: "tel",
    autocomplete: "cc-csc",
    rules: {
      required: { value: true, message: t("Required field") },
      minLength: { value: 3, message: t("Input is too short") },
      maxLength: { value: 4, message: t("Input is too long") },
    },
  };

  const formatCardNumber = (input: string) => {
    const digitsAndAsterisksOnly = input.replace(/[^0-9*]/g, "");
    // Split the string into groups of up to 4 characters
    const groups = digitsAndAsterisksOnly.match(/.{1,4}/g) || [];
    return groups.length > 4 ? groups.slice(0, 4).join(" ") : groups.join(" ");
  };

  const getNewCursorPosition = (
    text: string,
    currentCursorPosition: number
  ) => {
    const isPreviousCharASpace = text.charAt(currentCursorPosition - 1) === " ";
    return currentCursorPosition + (isPreviousCharASpace ? 1 : 0);
  };

  const handleBINLookup = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    updateFieldValue?: (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void
  ) => {
    try {
      if (updateFieldValue) {
        const field = event.target;
        const { selectionStart } = field;
        const formattedCardNumber = formatCardNumber(field.value);

        const currentCursorPos = selectionStart;
        field.value = formattedCardNumber;

        if (currentCursorPos) {
          const newPosition = getNewCursorPosition(
            field.value,
            currentCursorPos
          );

          field.selectionEnd = newPosition;
          field.selectionStart = newPosition;
        }

        updateFieldValue(event);
      }
      if (!pageResponse?.pageDetails?.iinLookupAvailable) {
        return;
      }

      const input = event.currentTarget.value;
      if (input && input.length > 6) {
        const bINLookupResponse = await debounceBINLookup({
          hostedPagesId,
          iINLookupRequest: { iin: input.replace(/\s/g, "").substring(0, 6) },
        });
        if (bINLookupResponse) {
          setMethods(
            bINLookupResponse.filter((method) => method && method.name)
          );
        }
      }
      return;
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <FbFormField formField={cardNumberField}>
          <Controller
            name={cardNumberField.name}
            defaultValue={""}
            control={control}
            render={({ onChange, onBlur, value, name }) => (
              <FbInput
                id={name}
                name={name}
                onChange={(e) => handleBINLookup(e, onChange)}
                onBlur={onBlur}
                value={value}
                endAdornment={
                  <PaymentInputEndAdornment
                    methodName={pageResponse?.pageDetails?.method?.name}
                    logoUrl={pageResponse?.pageDetails?.method?.logoUrl}
                    displayName={pageResponse?.pageDetails?.method?.displayName}
                    paymentMethods={methods}
                  />
                }
              />
            )}
          />
          <ErrorText formField={cardNumberField} errors={errors} />
          {methods.length <= 0 ||
          !pageResponse?.pageDetails?.iinLookupAvailable ? (
            <ErrorText formField={cardBrandField} errors={errors} />
          ) : null}
        </FbFormField>
        {methods && methods.length > 0 && (
          <FbRadioGroup
            field={cardBrandField}
            defaultValue={
              methods &&
              methods.length > 0 &&
              pageResponse?.pageDetails?.method?.name === Items.Card
                ? methods[0].name
                : String(pageResponse?.pageDetails?.method?.name)
            }
            control={control}
          >
            {methods.map((brand) => (
              <FormControlLabel
                aria-label={t(cardBrandField.name)}
                key={brand.name}
                value={brand.name}
                control={<Radio color="primary" />}
                label={
                  <Grid container>
                    <CreditCardLogo
                      src={brand.logoUrl}
                      alt={brand.displayName}
                    />
                  </Grid>
                }
              />
            ))}
          </FbRadioGroup>
        )}
      </Grid>

      <Grid item sm={8} xs={12}>
        <Box mr={isMobile ? 0 : 2}>
          <FbFormField formField={expiryFormField}>
            <Controller
              name={expiryFormField.name}
              defaultValue={""}
              control={control}
              placeholder={expiryFormField.placeholder}
              render={({ onChange, onBlur, value, name }) => (
                <FbInput
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder={expiryFormField.placeholder}
                  inputComponent={MaskedExpiryInput as any}
                />
              )}
            />
            <ErrorText formField={expiryFormField} errors={errors} />
          </FbFormField>
        </Box>
      </Grid>

      <Grid item sm={4} xs={12} key={securityCodeField.name}>
        <FbFormField formField={securityCodeField}>
          <FbInput
            id={securityCodeField.name}
            name={securityCodeField.name}
            inputRef={register(securityCodeField.rules)}
            type={securityCodeField.type}
            autoComplete={securityCodeField.autocomplete}
          />
          <ErrorText formField={securityCodeField} errors={errors} />
        </FbFormField>
      </Grid>

      {/* Will output any additional fields, like cardholder name */}
      {formFields.map((formField) => (
        <Grid item xs={12} key={formField.name}>
          <FbFormField formField={formField}>
            <FbInput
              id={formField.name}
              name={formField.name}
              inputRef={register(formField.rules)}
              type={formField.type}
              autoComplete={formField.autocomplete}
            />
            <ErrorText formField={formField} errors={errors} />
          </FbFormField>
        </Grid>
      ))}

      <Grid container item justify={"flex-end"}>
        <Box
          marginTop={3}
          marginRight={1}
          display={
            pageResponse?.pageDetails?.iinLookupAvailable ? "none" : "block"
          }
        >
          <Button
            variant="outlined"
            color="primary"
            type="reset"
            onClick={() => restart({ hostedPagesId })}
            disabled={isLoading}
          >
            {t("Cancel")}
          </Button>
        </Box>
        <Box marginTop={3}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.root}
            disabled={isLoading}
          >
            {t("Submit")}
          </Button>
        </Box>
      </Grid>
    </>
  );
};
