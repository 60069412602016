import { makeStyles } from "@material-ui/core";
import React from "react";
import useHostedPagesId from "../../../../hooks/use-hosted-pages-id";
import validateApplePayMerchant from "../../../../mutations/validate-apple-pay-merchant";
import usePageResponse from "../../../../hooks/use-page-response";
import { ClientSessionType, Page } from "../../../../api/client";

interface Props {
  payment: Object;
}

const useStyles = makeStyles({
  root: {
    "-webkit-appearance": "-apple-pay-button",
    "-apple-pay-button-type": "plain",
    "-apple-pay-button-style": "white-outline",
    display: "inline-block",
    cursor: "pointer",
  },
});

const APPLE_VERSION = 12;

const ApplePayButton: React.FC<Props> = (props) => {
  const hostedPagesId = useHostedPagesId();
  const {
    provideDetails: [provideDetails],
  } = usePageResponse(hostedPagesId);

  const { payment } = props;
  const classes = useStyles();

  async function initApplePaySession(payment: Object) {
    const applePaySession = new window.ApplePaySession(APPLE_VERSION, payment);

    applePaySession.begin();

    applePaySession.onvalidatemerchant = async () => {
      const { data } = await validateApplePayMerchant({
        hostedPagesId,
      });

      applePaySession.completeMerchantValidation(
        data.sessionTypeSpecificOutput?.applePay
      );

      applePaySession.onpaymentauthorized = async (
        event: ApplePayJS.ApplePayPaymentAuthorizedEvent
      ) => {
        const base64 = btoa(JSON.stringify(event.payment.token.paymentData));

        const data = await provideDetails({
          hostedPagesId: hostedPagesId,
          provideDetailsRequest: {
            method: ClientSessionType.APPLEPAY,
            mobilePayDetails: {
              paymentData: base64,
            },
          },
        });

        const { STATUS_SUCCESS, STATUS_FAILURE } = applePaySession;

        const isSuccess = data?.page === Page["MerchantSuccess"];

        const result = {
          status: isSuccess ? STATUS_SUCCESS : STATUS_FAILURE,
        };

        applePaySession.completePayment(result);
      };
    };
  }

  return (
    <div
      onClick={() => initApplePaySession(payment)}
      className={classes.root}
    ></div>
  );
};

export default ApplePayButton;
